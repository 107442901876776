import { Module, VuexModule } from "vuex-module-decorators";
import Store from "../index";

@Module({
    dynamic: true,
    store: Store,
    name: "layoutImages",
    namespaced: true
})
export default class LayoutImages extends VuexModule {
    get layoutImages() {
        return this.context.rootGetters.layoutImages;
    }

    get getLayoutImage() {
        return (imageName: string) => {
            let returnvalue = this.layoutImages?.[imageName];

            if (returnvalue) {
                returnvalue = `${process.env.BASE_URL}bg/${returnvalue}`;
            } else {
                returnvalue = "";
            }

            return returnvalue;
        };
    }
}
