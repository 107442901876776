















import { Component, Vue } from "vue-property-decorator";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

@Component({
    components: {
        PageTitleHeader
    }
})
export default class PostersView extends Vue {
    get pageConfig() {
        return this.$store.getters.getPageOptions("posters") || {};
    }
    get textHeader() {
        return this.pageConfig?.textHeader
            ? this.pageConfig?.textHeader
            : "Poster Gallery";
    }
}
