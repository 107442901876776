









import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
const layoutImagesStore = getModule(layoutImagesVuexModule);

@Component({})
export default class PageTitleHeader extends Vue {
    @Prop({ default: "" })
    pageAccessor!: string;

    get layoutImagePagePrimary() {
        if (layoutImagesStore.getLayoutImage(this.pageAccessor)) {
            return layoutImagesStore.getLayoutImage(this.pageAccessor);
        }
        return layoutImagesStore.getLayoutImage("pagePrimary");
    }

    get imgClasses() {
        return this.$store.getters.layoutImages.classToInclude;
    }

    get bgImageStyle() {
        if (this.layoutImagePagePrimary) {
            return "background-image:url(" + this.layoutImagePagePrimary + ")";
        } else {
            return "";
        }
    }
}
